<template>
  <div class="artist">
    artist
  </div>
</template>

<script>
export default {
  name: 'Artist',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.artist {
}
</style>